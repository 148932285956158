.textTruncate {
  max-width: 250px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.toolTip {
  color: #252724;
  font-size: 12px;
  font-weight: 500;
  background-color: #d8d6f9;
  border-radius: 8px;
  border: 1px solid #2f2a89;
  z-index: 100000;
}
.toolTipArrow {
  color: #fff;
  border-right: 1px solid #2f2a89;
  border-bottom: 1px solid #2f2a89;
}
